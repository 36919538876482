import { createRef, useState, useMemo, useEffect } from 'react'
import { Route, Routes, Outlet, Navigate, useNavigate } from 'react-router-dom'
import ProtectedRoutes from 'routers/private'
import RouteGuest from 'routers/routeGuest'
import UserContext from 'core/contexts/userContext'
import { getData, getMe } from 'core/action/collection'
import Header from 'components/Layout/Header/Header'
import Homepage from 'pages/Homepage'
import HomePageGuest from 'pages/HomePageGuest'
import Reportpage from 'pages/Report'
import Chatpage from 'pages/Chat'
import Complaintpage from 'pages/Complaint'
import LoginPage from 'pages/Login'
import LoginEmailPage from 'pages/LoginEmail'

import FormRegister from 'pages/ResultPage/FormRegister'
import FormRegisterByEmail from 'pages/ResultPage/FormRegisterByEmail'
import FormCreateComplaint from 'pages/CreateComplaint/FormCreateComplaint'
import FormCreateEservice from 'pages/CreateComplaint/FormCreateEservice'
import FormCreateComplaintGuest from 'pages/CreateCompalintGuest/FormCreateComplaintGuest'
import FormCreateEserviceGuest from 'pages/CreateCompalintGuest/FormCreateEserviceGuest'
import FormViewComplaint from 'pages/ViewComplain'
import FormEditComplaint from 'pages/Editcomplain'
import EditComplaintPage from 'pages/EditComplaintPage'
import EditEservicePage from 'pages/EditEservicePage'
import { SearchProvider } from 'core/contexts/useSearch'
import { NotificationProvider } from 'core/contexts/useNotification'
import ConsentPage from 'pages/ResultPage/ConsentPage'
import ChatBot from 'components/ChatBot'
import LoginEmail from 'pages/ResultPage/LoginEmail'

import './App.css'

function App() {
  const navigate = useNavigate()
  const [meData, setMeData] = useState()
  const getToken = localStorage.getItem('access_token')
  const getMeData = async () => {
    const res = await getMe({ token: getToken })
    if (res?.code === 200) {
      setMeData(res.data)
    } else if (res?.code === 401) {
      localStorage.clear()
      navigate('/login')
    }
  }

  useEffect(() => {
    if (getToken) {
      getMeData()
    } else {
      setMeData([])
    }
  }, [getToken])

  const CoreLayout = ({ subMenu = [], offContainer = false }) => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <Header noFix={true} noLogo={true} />
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </>
    )
  }
  return (
    <UserContext.Provider value={{ meData, setMeData, getMeData }}>
      <SearchProvider>
        <NotificationProvider>
          {meData?.line_id && <ChatBot />}
          <Routes>
            <Route path="/" element={<Navigate to={getToken ? '/home' : '/home-guest'} />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<CoreLayout offContainer={false} />}>
                <Route path="/home" element={<Homepage />} />
                <Route path="/report" element={<Reportpage.List />} />
                <Route path="/chat" element={<Chatpage.View />} />
                <Route path="/create-complaint" element={<FormCreateComplaint />} />
                <Route path="/create-e-service" element={<FormCreateEservice />} />
                <Route path="/home-guest" element={<HomePageGuest />} />
                <Route path="/create-complaint-guest" element={<FormCreateComplaintGuest />} />
                <Route path="/create-e-service-guest" element={<FormCreateEserviceGuest />} />
              </Route>
              <Route path="/complaint" element={<CoreLayout offContainer={true} />}>
                <Route index element={<Complaintpage.List />} />
              </Route>
            </Route>

            <Route element={<RouteGuest />}>
              <Route path="/" element={<CoreLayout offContainer={false} />}>
                <Route path="/home-guest" element={<HomePageGuest />} />
              </Route>
            </Route>
            <Route path="/login-email" element={<LoginEmailPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login-email" element={<LoginEmail />} />
            <Route path="/consent" element={<ConsentPage />} />
            <Route path="/register" element={<FormRegister />} />
            <Route path="/register-email" element={<FormRegisterByEmail />} />
            <Route path="/view-complaint" element={<FormViewComplaint />} />
            <Route path="/edit-complaint" element={<FormEditComplaint />} />
            <Route path="/edit-complaint/:id" element={<EditComplaintPage />} />
            <Route path="/edit-e-service/:id" element={<EditEservicePage />} />
          </Routes>
        </NotificationProvider>
      </SearchProvider>
    </UserContext.Provider>
  )
}
export default App
