import styled from 'styled-components'

export const Container = styled.div`
  /* display: flex; */
  width: 100%;
  height: 100%;
  /* min-height: 720px; */
  /* overflow: auto; */

  background-image: ${props => (props.url ? `url(${props.url})` : '#fcfcfc')};
  background-position: ${props => (props.mobile ? `0% 375%` : 'center center')};
  background-repeat: ${props => (props.mobile ? `none` : 'no-repeat')};
  background-size: ${props => (props.mobile ? `none` : 'cover')};

  /* background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */

  /* align-items: center;
  justify-content: center;
  flex-direction: column; */

  /* @media only screen and (max-width: 414px) {
    background-position: 0% 310%;
    background-repeat: none !important;
    background-size: none !important;
  } */

  .ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
  }
`
