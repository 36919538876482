import React from 'react'
import { Container } from './loginemail.style'

const LoginEmail = () => {
  return (
    <>
      <Container>LoginEmail</Container>
    </>
  )
}

export default LoginEmail
